import Vue from "vue";
import Vuex from "vuex";
import VuexPersistence from "vuex-persist";
import api from "./modules/api";
import settings from "./modules/settings";
import snackbar from "./modules/snackbar";
import user from "./modules/user";
import heartbeat from "./modules/heartbeat";
import cache from "./modules/cache";

export const VUEX_KEY = "ranesportal";

Vue.use(Vuex);

const vuexLocal = new VuexPersistence({
    key: VUEX_KEY,
    storage: window.localStorage,
    reducer: (state) => ({
        settings: state.settings,
        cache: state.cache,
    }),
});

export default new Vuex.Store({
    modules: {
        api,
        settings,
        snackbar,
        user,
        heartbeat,
        cache,
    },
    plugins: [vuexLocal.plugin],
});
